import React, { useState, useEffect } from 'react'
import styled from '@emotion/native'
import { COLOR, VIEW } from '~/const'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import { FormFieldSeparator } from '~/components/stateless/Form'

export default function Draw({ onDrawConfirm }) {
    return (
        <Dialog width={700} height={400}>
            <Container>
                <Content>
                    <TextContainer>
                        <Text>{translate('Accept Draw?')}</Text>
                    </TextContainer>

                    <FormFieldSeparator />
                    <FormFieldSeparator />

                    <Buttons>
                        <Button
                            width={'45%'}
                            fontSize={35}
                            color={COLOR.GREEN}
                            label={translate('Yes')}
                            onClick={() => {
                                AUDIO.CLOSE()
                                onDrawConfirm({ confirm: true })
                            }}
                        />
                        <ButtonSeparator />
                        <Button
                            width={'45%'}
                            fontSize={35}
                            label={translate('No')}
                            onClick={() => {
                                AUDIO.CLOSE()
                                onDrawConfirm({ confirm: false })
                            }}
                        />
                    </Buttons>
                </Content>
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: ${px(20)};
`

const Content = styled.View`
    justify-content: center;
    align-items: center;
    width: 100%;
`
const TextContainer = styled.View`
    justify-content: center;
    align-items: center;
`
const Text = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWN};
    font-size: ${px(35)};
    text-align: center;
`

const Buttons = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
`

const ButtonSeparator = styled.View`
    width: ${px(20)};
`

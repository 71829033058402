const { GAME_ACTION, TEAM, PLAYER, PLAYERS_TEAM, TIMES } = require('../const')

module.exports = [
    {
        type: GAME_ACTION.SET_BOARD,
        params: {
            name: 'FIRST_BATTLE',
            title: 'First Battle',

            cols: 25,
            rows: 19,
            units_player: 8,
            flags_to_win: 8,
            power_by_flag: 10,

            teams: {
                [TEAM.TEAM_1]: {
                    drawing: null, // { date: Date.now() }
                    tiles: { from: '0.0', to: '24.1' }, // '24.16'
                },

                [TEAM.TEAM_2]: {
                    drawing: null,
                    tiles: { from: '0.17', to: '24.18' },
                },
            },

            players: {
                [PLAYER.PLAYER_1]: {
                    team_id: PLAYERS_TEAM[PLAYER.PLAYER_1],
                    power: 100,
                    cards: [],
                    recruited: 0,
                    turn_started: null,
                    turn_bonus: TIMES.BONUS_START,
                },
                [PLAYER.PLAYER_2]: {
                    team_id: PLAYERS_TEAM[PLAYER.PLAYER_2],
                    power: 100,
                    cards: [],
                    recruited: 0,
                    turn_started: null,
                    turn_bonus: TIMES.BONUS_START,
                },
            },
        },
    },

    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_1', tile_id: '1.3' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_2', tile_id: '23.15' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_3', tile_id: '6.15' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_4', tile_id: '18.3' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_5', tile_id: '12.5' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_6', tile_id: '12.13' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_7', tile_id: '6.4' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_8', tile_id: '18.14' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_9', tile_id: '24.6' },
    },
    {
        type: GAME_ACTION.FLAG_ADD,
        params: { flag_id: 'flag_10', tile_id: '0.12' },
    },
]
